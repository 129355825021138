import { Drawer, Layout, Menu } from "antd";
import styled, { css } from "styled-components";
import Button from "../Button";

export const ContentButton = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border-top: 2px solid #313131;
  border-bottom: 2px solid #313131;

  .plan-sidebar {
    font-size: 16px;
    user-select: none;
    color: var(--velotax-font-color-light);
    &.pointer {
      cursor: pointer;
    }
    strong {
      font-weight: 900;
    }
  }
`;

export const DrawerSider = styled(Drawer)`
  z-index: 99999999999;
  .ant-drawer-wrapper-body {
    position: relative;
    .ant-drawer-header {
      right: 16px;
      top: 24px;
      padding: 0;
      position: absolute;
      background-color: var(--velotax-background-color);
    }
    .ant-drawer-body {
      padding: 0;
      background-color: var(--velotax-background-color);
    }
  }
`;

export const LayoutSider = styled(Layout.Sider)`
  box-shadow: 8px 8px 32px 16px rgba(0, 0, 0, 0.05);
  position: relative;
  z-index: 11;

  .ant-layout-sider-children {
    max-height: 100dvh;
    display: flex;
    flex-direction: column;
  }
`;

export const Modules = styled(Menu)`
  border: none;
  padding-top: 8px;
  overflow-y: auto;
  overflow-x: hidden;
  /* height: calc(100% - 96px - 70px); */
  height: 100%;
  border-top: 2px solid #313131;
  &.user {
    /* 100% - header - botão de plano - botao de sair */
    /* height: calc(100% - 96px - 70px - 72px); */
    height: 100%;
  }
  &.poweredBy {
    /* 100% - header - poweredby - botao de sair */
    height: calc(100% - 96px - 70px - 64px);
  }
  &.user.poweredBy {
    /* 100% - header - botão de plano - poweredby - botao de sair */
    height: calc(100% - 96px - 70px - 64px - 72px);
  }
  @media only screen and (max-device-width: 812px) {
    height: calc(100% - 64px - 72px);
    &.user {
      height: calc(100% - 64px - 72px - 72px);
    }
    &.poweredBy {
      height: calc(100% - 64px - 72px - 64px);
    }
    &.user.poweredBy {
      height: calc(100% - 64px - 72px - 72px - 64px);
    }
  }
`;

export const Settings = styled(Menu)`
  width: 100%;
  height: 72px;
  border: none;
  display: flex;
  border-top: 2px solid #313131;

  .ant-row {
    width: 100%;
  }
`;

interface ModuleProps {
  sidebarselectedcolor: string;
}

export const Module = styled(Menu.Item)<ModuleProps>`
  border: none;
  height: 48px !important;
  margin: 6px 0 !important;
  padding-left: 12px !important;
  background-color: var(--velotax-background-color);

  * {
    z-index: 1;
  }

  svg {
    width: 20px;
    height: 20px;
    margin-right: 8px;
    color: var(--velotax-font-color-ghost);
    &.stroke {
      transition: stroke 0.3s;
      stroke: var(--velotax-font-color-ghost);
    }
  }

  .ant-menu-title-content {
    font-weight: 500;
    margin-left: 0 !important;
    color: var(--velotax-font-color-ghost);
  }

  &.ant-menu-item:hover {
    .ant-menu-title-content {
      color: var(--ant-primary-color);
    }
    svg {
      color: var(--ant-primary-color);
      &.stroke {
        stroke: var(--ant-primary-color);
      }
    }
  }

  &.ant-menu-item:active {
    background-color: var(--velotax-background-color);
  }

  &.ant-menu-item-selected {
    .ant-menu-title-content {
      font-weight: 600;
      color: var(--ant-primary-color);
    }
    svg {
      color: var(--ant-primary-color);
      &.stroke {
        stroke: var(--ant-primary-color);
      }
    }
    background-color: var(--velotax-background-color) !important;

    &::after {
      border-right: none;
      opacity: 0;
    }

    &::before {
      opacity: 1;
    }
  }

  &::before {
    content: "";
    top: 0;
    left: 0;
    z-index: 0;
    opacity: 0;
    height: 100%;
    position: absolute;
    width: calc(100% - 8px);
    border-radius: 0 4px 4px 0;
    background-color: var(--velotax-ghost);
    transition: opacity 500ms ease-in-out;
  }

  &.conta {
    width: 100%;
    border: none;
    margin: 0 !important;
    height: 72px !important;
    padding: 12px 8px 4px 8px !important;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    &::before {
      top: 6px;
      left: 8px;
      border-radius: 6px;
      width: calc(100% - 16px);
      height: calc(100% - 12px);
    }

    svg {
      width: 20px;
      height: 20px;
      min-width: 20px;
      min-height: 20px;
      margin-right: 0;
      stroke: var(--velotax-font-color-ghost);
      &.sair {
        transition: fill 0.3s, stroke 0.3s;
        fill: var(--velotax-font-color-ghost);
      }
      &.stroke {
        transition: stroke 0.3s;
      }
    }

    .ant-menu-title-content {
      margin: 0;
    }

    :hover {
      svg.stroke {
        stroke: var(--ant-primary-color);
      }
    }

    &.logout {
      border-left: 2px solid #313131;
      &::before {
        opacity: 0;
      }
      :hover {
        .ant-menu-title-content {
          color: var(--ant-error-color);
        }
        svg {
          stroke: var(--ant-error-color);
          fill: var(--ant-error-color);
        }
      }
    }
  }

  ${(props) =>
    props.sidebarselectedcolor
      ? css`
          &:hover {
            color: ${props.sidebarselectedcolor} !important;
          }
          &.ant-menu-item-selected {
            color: ${props.sidebarselectedcolor} !important;
            &:hover {
              color: ${props.sidebarselectedcolor};
            }
          }
        `
      : css`
          &:hover {
            color: var(--ant-primary-color) !important;
            svg {
              color: var(--ant-primary-color) !important;
            }
            svg:not(.no-fill) {
              fill: var(--ant-primary-color) !important;
            }
          }
          &.ant-menu-item-selected {
            color: var(--ant-primary-color) !important;
            svg {
              color: var(--ant-primary-color);
            }
            svg:not(.no-fill) {
              fill: var(--ant-primary-color);
            }
            &:hover {
              color: var(--ant-primary-color);
            }
          }
        `}
`;

export const FeaturePromo = styled.div`
  height: 72px;
  display: flex;
  cursor: pointer;
  position: relative;
  align-items: center;
  padding: 0 16px 0 12px;
  border-top: 2px solid #313131;

  :before {
    content: "";
    top: 12px;
    left: 0;
    z-index: 0;
    opacity: 0;
    position: absolute;
    width: calc(100% - 8px);
    height: calc(100% - 24px);
    border-radius: 0 4px 4px 0;
    background-color: var(--velotax-ghost);
    -webkit-transition: opacity 500ms ease-in-out;
    transition: opacity 500ms ease-in-out;
  }

  &.active {
    .title-features {
      color: var(--ant-primary-color);
    }
    svg {
      color: var(--ant-primary-color);
      stroke: var(--ant-primary-color);
    }
    :before {
      opacity: 1;
    }
  }

  .title-features {
    z-index: 1;
    height: 40px;
    font-size: 14px;
    font-weight: 500;
    line-height: 42px;
    transition: color 0.3s;
    color: var(--velotax-font-color-ghost);
  }

  svg {
    z-index: 1;
    font-size: 14px;
    min-width: 14px;
    margin-right: 8px;
    color: var(--velotax-font-color-ghost);
    stroke: var(--velotax-font-color-ghost);
    transition: stroke 0.3s;
  }

  :hover {
    color: var(--ant-primary-color);

    .title-features {
      color: var(--ant-primary-color);
    }

    svg {
      color: var(--ant-primary-color);
      stroke: var(--ant-primary-color);
    }
  }
`;

export const FeatureButton = styled(Button)`
  font-size: 8px !important;
  padding: 6px !important;
  height: auto !important;
  top: 4px !important;
  line-height: 1 !important;
  right: -6px !important;

  @media screen and (max-device-width: 812px) {
    right: -2px !important;
  }
`;

export const IntegrationFeedback = styled.div<{
  percent: number;
}>`
  background-image: ${({ percent }) => `linear-gradient(to right, #fbc105 ${percent}%, transparent ${percent}%)`};
  width: 75%;
  transition: background 0.5s ease-out;
  background-repeat: no-repeat;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #fbc10520;
  border-radius: 20px;
  overflow: hidden;
  font-weight: 800;
  text-align: center;
`;

export const SupportContainerIcon = styled.div`
  & svg {
    height: 17px;
    width: 17px;
    margin-left: 2px;
  }
`