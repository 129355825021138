import { BiCheck } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { Tooltip, Typography, message } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useCallback, useEffect, useState } from "react";
import Button from "../../components/Button";
import HandleTag from "../../services/handleTag";
import { useAuth } from "../../contexts/AuthContext";
import { UserPlanEnum } from "../../constants/plans";
import { Container, PlanosContainer, LogoContainer } from "./styles";
import { apiPayment } from "../../services/apiPayment";
import { PaymentModal } from "../../components/PaymentModal";
import { UserPlanUpgradeEnum, getPlanPrice, planos } from "./planos";
import { ReactComponent as Selo7dias } from "../../assets/icons/selo-7-dias.svg";
import { ReactComponent as SeloGarantia } from "../../assets/icons/selo-garantia.svg";
import { ReactComponent as SeloGoogleStore } from "../../assets/icons/selo-play-store.svg";
import { ReactComponent as SeloAppStore } from "../../assets/icons/selo-app-store.svg";


var getStatus: NodeJS.Timeout;

export const Planos = () => {
  const navigate = useNavigate();
  const {
    user,
    hasPlan,
    // isIOSAPP,
    getUserInfo,
    // directPayment,
    // initIOSPlanCheck,
    // setDirectPayment,
  } = useAuth();
  const [selectedPlan, setSelectedPlan] = useState<any>();
  const [paymentModal, setPaymentModal] = useState(false);
  const [planUpgrade, setPlanUpgrade] = useState<UserPlanUpgradeEnum>();

  const handleSelectPlan = (plan: any) => {
    if (false) {
      // initIOSPlanCheck();
      window.location.href = !process.env.REACT_APP_URL?.includes("dev")
        ? "https://checkout.velotax.com.br/"
        : "https://checkout-dev.velotax.com.br/";
    } else {
      setPaymentModal(true);
    }
    setSelectedPlan(plan);
  };

  const checkWasPaid = useCallback(
    (id: string, delay: number = 2) => {
      let attempts = 0;
      getStatus = setInterval(async () => {
        apiPayment
          .get(`/user-plan/plan-info`)
          .then((res) => {
            if (res.data?.active) {
              clearInterval(getStatus);
              message.success("Pagamento Confirmado com sucesso!");
              HandleTag("75");
              getUserInfo();
              user?.user?.isAd
                ? navigate("/sucesso?ad=true")
                : navigate("/sucesso");
            }
          })
          .finally(() => {
            attempts += 1;
            if (attempts > 100) {
              clearInterval(getStatus);
            }
          });
      }, delay * 1000);
    },
    [navigate, getUserInfo, user?.user?.isAd]
  );

  const handleSuccess = (data: any) => {
    checkWasPaid(data.paymentId, 4);
  };

  const stopIntervalPix = () => {
    if (getStatus) clearInterval(getStatus);
  };

  useEffect(() => {
    return () => getStatus && clearInterval(getStatus);
  }, [checkWasPaid]);

  useEffect(() => {
    // setPaymentModal(true);
    setSelectedPlan(
      planos.find(
        (plan) => plan.type === user.user.userPlanInfoVelotax?.type
      ) ?? planos[1]
    );
  }, [user?.user?.userPlanInfoVelotax?.type]);

  return (
    <Container className="velotax-container">
      <div className="velotax-content">
        <Typography.Title className="page-title">
          Selecione seu plano
        </Typography.Title>
        <Typography.Text className="subtitle">
          Junte-se a nossa comunidade com mais de{" "}
          <strong>500 mil usuários satisfeitos!</strong>
        </Typography.Text>
        {/* <TrustPilotTrustBox /> */}
        <LogoContainer className="selos">
          <div className="selo">
            <SeloGoogleStore />
          </div>
          <div className="selo right-border">
            <SeloAppStore />
          </div>
          <div className="selo left-border">
            <SeloGarantia />
          </div>

          <div className="selo tooltip-container">
            <div className="svg-container">
              <Selo7dias />
              <div className="tooltip">
                <Tooltip title="Oferecemos uma garantia de satisfação de sete dias para a sua contratação. Se, por qualquer motivo, você não estiver satisfeito, reembolsaremos integralmente o valor investido, desde que não tenhamos emitido DARFs ou gerado relatórios durante esse período.">
                  <InfoCircleOutlined />
                </Tooltip>
              </div>
            </div>
          </div>
        </LogoContainer>
        <PlanosContainer>
          {planos.map((plan) => {
            const actionTypeText = !hasPlan
            ? "Selecionar"
            : (hasPlan.basic &&
                plan?.type === UserPlanEnum.VELOTAX_MAIN_BASIC) ||
              (hasPlan.premium &&
                plan?.type === UserPlanEnum.VELOTAX_MAIN_PRO) ||
              (hasPlan.concierge &&
                plan?.type === UserPlanEnum.VELOTAX_MAIN_CONCIERGE)
            ? "Ativo"
            : "Fazer upgrade"

            const price = getPlanPrice(plan, hasPlan)
            ?.toLocaleString("pt-br", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
            const isUpgrade = actionTypeText === "Fazer upgrade"

            return (
              <div className="plan" key={plan.name}>
                {plan.popular && <div className="plan-popular">Popular</div>}
                <Typography.Paragraph className="plan-name">
                  {plan.name}
                </Typography.Paragraph>
                <Typography.Paragraph className="plan-price">
                  {isUpgrade ? "+ " : ""}R${" "}
                  <span className="plan-price-main">{price.split(",")[0]}</span>
                  ,{price.split(",")[1]}
                </Typography.Paragraph>
                <Typography.Text className="plan-price-desc">
                  por mês no plano anual
                </Typography.Text>
                <Typography.Text className="plan-for">
                  {plan.for}
                </Typography.Text>
                <Button
                  block
                  size="large"
                  type="primary"
                  disabled={
                    hasPlan &&
                    (
                      (hasPlan.basic && plan?.type === UserPlanEnum.VELOTAX_MAIN_BASIC) ||
                      (hasPlan.premium && plan?.type === UserPlanEnum.VELOTAX_MAIN_PRO) ||
                      (hasPlan.concierge && plan?.type === UserPlanEnum.VELOTAX_MAIN_CONCIERGE)
                    )
                  }
                  onClick={() => {
                    if (hasPlan) {
                      setPlanUpgrade(
                        hasPlan.premium &&
                          plan?.type === UserPlanEnum.VELOTAX_MAIN_CONCIERGE
                          ? UserPlanUpgradeEnum.PRO_TO_CONCIERGE
                          : !hasPlan.premium &&
                            plan?.type === UserPlanEnum.VELOTAX_MAIN_PRO
                          ? UserPlanUpgradeEnum.BASIC_TO_PRO
                          : !hasPlan.premium &&
                            plan?.type === UserPlanEnum.VELOTAX_MAIN_CONCIERGE
                          ? UserPlanUpgradeEnum.BASIC_TO_CONCIERGE
                          : undefined
                      );
                    }
                    handleSelectPlan(plan);
                  }}
                >
                  {actionTypeText}
                </Button>
                <Typography.Text className="plan-includes">
                  {plan.includes}
                </Typography.Text>
                <div className="plan-benefits">
                  {plan.benefits.map((benefit, index) => (
                    <div className="plan-benefit" key={index}>
                      <BiCheck className="check" />
                      {benefit.split("{")[0]}
                      {benefit.split("{")?.[1]?.length > 0 ? (
                        <Tooltip
                          title={benefit.split("{")?.[1].replace("}", "")}
                          getPopupContainer={(target) => target.parentElement!}
                        >
                          <InfoCircleOutlined className="info" />
                        </Tooltip>
                      ) : (
                        <></>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            )
          })}
        </PlanosContainer>
      </div>
      <PaymentModal
        hideDarfDownload
        show={paymentModal}
        callDarf={() => {}}
        planCurrent={selectedPlan}
        onSucessPix={checkWasPaid}
        onSucessCreditCard={handleSuccess}
        hidePixButton={
          "VELOTAX_MAIN_PRO_MONTH" === selectedPlan?.type || planUpgrade !== undefined
        }
        paymentData={{
          planUpgrade,
          type: selectedPlan?.type,
          isPlan: true,
          stopIntervalPix,
        }}
        onCancel={(e: any) => {
          setPaymentModal(false);
          setSelectedPlan(undefined);
          // setDirectPayment(false);
        }}
      />
    </Container>
  );
};